<template>
  <i-modal :title="$t('Add/Edit Form')">
    <form-group>
      <label class="form-label" for="form_name">{{ $t('Form name') }}</label>
      <b-input type="text" size="lg" id="form_name" v-model="addEditForm.form_name"/>
    </form-group>
    <form-group>
      <label class="form-label" for="m_calculator">{{ $t('Mortgage calculator') }}</label>
      <b-select id="m_calculator" v-model="addEditForm.mortgage_calculator">
        <option value="">{{ $t('Select')}}</option>
        <option v-for="calc in mortgageCalculators" :key="'m_calc'+calc.id" :value="calc.id">{{ calc.nice_name }}</option>
      </b-select>
      <form-note>{{ $t('The mortgage calculator to use with this form.') }}</form-note>
    </form-group>
    <form-group>
      <label class="form-label" for="form_description">{{ $t('Description') }}</label>
      <textarea class="form-control" id="form_description" v-model="addEditForm.description"></textarea>
    </form-group>
    <template v-slot:footer class="p-5">
      <nk-button type="primary" class="mr-2" v-on:click="addUpdateForm()">{{ $t(addEditForm.id > 0 ? 'Update' : 'Add') }}</nk-button>
      <a href="javascript:;" class="link link-light" v-on:click="closeModal">{{ $t('Cancel') }}</a>
    </template>
  </i-modal>
</template>

<script>
import IModal from "@core/components/ion-modal/IModal";
import FormGroup from "@core/layouts/form-group/FormGroup";
import BInput from "@core/components/bp-form/components/BInput";
import {reactive} from "vue";
import {loadingController, modalController} from "@ionic/vue";
import {useI18n} from "vue-i18n";
import axios from "@/libs/axios";
import useIonComponents from "@core/IonComponents"
import FormNote from "@core/components/bp-form/components/FormNote";
import BSelect from "@core/components/bp-form/components/BSelect";
export default {
  name: "AddEditLeadForm",
  components: {BSelect, FormNote, BInput, FormGroup, IModal},
  props:{
    formData: {
      required: true,
      type: Object,
    },
    mortgageCalculators:{
      required: true,
      type: Object,
    }
  },
  emits: ['onDataUpdate'],
  setup(props, {emit}){

    const{IonToast} = useIonComponents()
    const{t} = useI18n()
    let addEditForm = reactive({
      id: props.formData.id || 0,
      form_name: props.formData.form_name || '',
      mortgage_calculator: props.formData.mortgage_calculator?.id || '',
      description: props.formData.description || '',
    })
    const addUpdateForm = async () => {
      const l = await loadingController.create({message: t('Please wait...')})
      await l.present()
      axios.put('/api/admin/leads/forms/set', addEditForm)
          .then(r=>{
            emit('onDataUpdate', r.data.data)
          })
          .catch(er=>{
            let msg = t('errors.general_error')
            msg = er.response ? (er.response.data.message || er.response.status+' '+er.response.statusText) : msg
            IonToast({message: msg, duration: 3500, position: 'top', color: 'danger'})
          })
          .then(()=> l.dismiss())
    }
    const closeModal = async () => {
      await modalController.dismiss()
    }

    return{
      addEditForm,
      addUpdateForm,
      closeModal,
    }
  }
}
</script>
