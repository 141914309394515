<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Leads Forms')" :description="$t('Total')+'  leads.'">
        <block-tools>
          <li>
            <nk-button type="primary" v-on:click="newForm()">
              <nio-icon icon="ni-plus"></nio-icon> {{$t('Create new form')}}
            </nk-button>
          </li>
        </block-tools>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <card-inner class="p-0">
              <list-table>
                <tb-row head-row>
                  <tb-th>{{ $t('ID')}}</tb-th>
                  <tb-th>{{ $t('Name')}}</tb-th>
                  <tb-th>{{ $t('Fields') }}</tb-th>
                  <tb-th>{{ $t('Mortgage calculator') }}</tb-th>
                  <tb-th class="nk-tb-col-tools"></tb-th>
                </tb-row>
                <tb-row v-for="(f,ndx) in leadsForms.forms" :key="'fls'+f.id">
                  <tb-td>{{ f.id }}</tb-td>
                  <tb-td>{{ f.form_name }}</tb-td>
                  <tb-td>{{ f.form_fields_count }}</tb-td>
                  <tb-td>{{ f.mortgage_calculator?.nice_name }}</tb-td>
                  <tb-td>
                    <ul class="nk-tb-actions gx-1">
                      <template v-if="isDesktop">
                        <table-action icon="ni-pen-fill"
                                      v-on:click="newForm(f)"
                                      v-tooltip="'Edit'"></table-action>
                        <table-action icon="ni-trash-fill" v-on:click="deleteForm(f,ndx)" v-tooltip="$t('Delete')"></table-action>
                        <table-action icon="ni-eye-fill" v-tooltip="$t('Open')" :to="{name: 'adminLeadsFormFields', params:{id:f.id}}"></table-action>
                      </template>
                      <li v-else>
                        <drop-down-list>
                          <list-item icon="ni-eye-fill" :text="$t('Open')" :to="{name: 'adminLeadsFormFields', params:{id:f.id}}"></list-item>
                          <list-item icon="ni-pen-fill" :text="$t('Edit')" v-on:click="newForm(f)"></list-item>
                          <list-item icon="ni-trash-fill" :text="$t('Delete')" v-on:click="deleteForm(f,ndx)"></list-item>
                        </drop-down-list>
                      </li>
                    </ul>
                  </tb-td>
                </tb-row>
              </list-table>
            </card-inner>
          </template>
        </card>
      </block-content>
    </main-block>
    <ion-modal :is-open="addEditForm.modalOpen"
               @didDismiss="addEditForm.modalOpen=false"
               :css-class="isDesktop ? 'modal-web': ''">
      <add-edit-lead-form :form-data="addEditForm.data" :mortgage-calculators="leadsForms.mortgage_calculators" @on-data-update="onFormUpdate"></add-edit-lead-form>
    </ion-modal>
  </page-template>
</template>

<script>
import {
  alertController,
  IonModal,
  isPlatform,
  loadingController,
  onIonViewWillEnter
} from '@ionic/vue';
import {defineComponent, reactive} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import "bootstrap"
import BlockHead from "@core/layouts/main-block/components/BlockHead";
import axios from "@/libs/axios";
import Card from "@core/components/cards/Card";
import CardInner from "@core/components/cards/components/CardInner";
import ListTable from "@core/components/list-table/ListTable";
import TbRow from "@core/components/data-tables/components/TbRow";
import TbTh from "@core/components/data-tables/components/TbTh";
import TbTd from "@core/components/data-tables/components/TbTd";
import TableAction from "@core/components/special-table/components/TableAction";
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";
import BlockTools from "@core/layouts/main-block/components/BlockTools";
import AddEditLeadForm from "@/views/admin/LeadsForms/components/AddEditLeadForm";
import {useI18n} from "vue-i18n";
import useIonComponents from "@core/IonComponents"
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    AddEditLeadForm,
    BlockTools,
    IonModal,
    ListItem,
    DropDownList,
    TableAction,
    TbTd,
    TbTh,
    TbRow,
    ListTable,
    CardInner,
    Card,
    BlockHead,
    BlockContent,
    MainBlock,
  },
  setup() {

    const{t} = useI18n()
    const{IonToast} = useIonComponents()
    const isDesktop = isPlatform('desktop')
    let leadsForms  = reactive({
      forms: [],
      mortgage_calculators: [],
      pagination:{
        page: 1,
        totalRecords: 0,
        perPage: 10,
      },
    })
    let addEditForm = reactive({
      modalOpen: false,
      data: {
        id: 0,
        mortgage_calculator:{
          id: 0,
          nice_name: '',
        },
        form_name: '',
        description: '',
      },
    })

    const getLeadsForms = (reset) => {
      if(reset){ leadsForms.page = 1}
      axios.get('/api/admin/leads/forms/list', {params:{page: 0}})
          .then(r=>{
            leadsForms.forms = r.data.data.forms
            leadsForms.mortgage_calculators = r.data.data.mortgage_calculators
            leadsForms.pagination.totalRecords = r.data.data.total_records
          })
          .catch(er=>{
            console.log(er)
          })
    }
    const onFormUpdate = (data) => {
      addEditForm.modalOpen = false
      if(addEditForm.data.id > 0){
        for(let x in leadsForms.forms){
          if(parseInt(leadsForms.forms[x].id) === parseInt(addEditForm.data.id)){
            leadsForms.forms[x] = data;
            break;
          }
        }
      }
      else{
        leadsForms.forms.unshift(data)
      }
    }
    const newForm = (d) => {
      addEditForm.data.id = d ? d.id : 0
      addEditForm.data.mortgage_calculator = d ? d.mortgage_calculator : {id: 0, nice_name: ''}
      addEditForm.data.form_name = d ? d.form_name : ''
      addEditForm.data.description = d ? d.description : ''
      addEditForm.modalOpen = true
    }
    const deleteForm = (form,index) => {
      alertController.create({
        cssClass: isDesktop ? 'alert-web' : '',
        header: t('Delete form'),
        message: t('Are you sure you want to delete this form?'),
        buttons: [
          {
            text: t('Cancel'),
            cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
          },
          {
            cssClass: isDesktop ? 'alert-btn-danger' : '',
            text: t('Delete'), handler: async () => {
              const l = await loadingController.create({message: t('Please wait...')})
              await l.present()
              axios.delete(`/api/admin/leads/forms/${form.id}`)
                  .then( () => {
                    leadsForms.forms.splice(index,1)
                    IonToast({message: t('Thank you! action completed'), position: 'top', color:'primary', duration: 3000})
                  })
                  .catch(er => {
                    let m = t('errors.general_error')
                    m = er.response ? (er.response.data.message || er.response.status+' '+er.response.statusText) : m
                    IonToast({message: m, position: 'top', color:'danger', duration: 3500})
                  })
                  .then( () => l.dismiss())
            }
          },
        ]
      }).then((iA) => iA.present())
    }

    onIonViewWillEnter(()=>{
      getLeadsForms()
    })

    return {
      addEditForm,
      deleteForm,
      getLeadsForms,
      isDesktop,
      leadsForms,
      newForm,
      onFormUpdate,
    }
  }
});

</script>
